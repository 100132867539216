import axios from "../utils/axios";
import { apiUrl } from "../../global_config";
import Vue from 'vue';
export default {
  state: {
    technologies: [],
  },
  getters: {
    technologies: (state) => state.technologies,
  },
  actions: {
    async getTechnologies({ commit }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios.get(apiUrl + "api/technologie", config).then((res) => {
        
        commit("SET_DATA_TECHNO", res.data.data);
      });
    },
    async updateTechnologyStatus({ commit }, { id, value }) {
      let status = value === true ? 1 : 2;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios
        .put(
          apiUrl + `api/technologie_status/${id}`,
          { status: status },
          config
        )
        .then((response) => {
          Vue.notify({
            group: 'foo',
            title: '',
            text: response.data.message
          })

          this.dispatch("getTechnologies");
        });
    },
  },
  mutations: {
    SET_DATA_TECHNO(state, data) {
      state.technologies = data;
    },
  },
};
