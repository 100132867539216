import axios from "../utils/axios";
import { apiUrl } from "../../global_config";

export default {
  state: {
    data: [],
    total: 0,
    isUploading: false,
  },
  getters: {
    data: (state) => state.data,
  },
  actions: {
    async getData({ commit }, { per_page, page }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios
        .get(
          apiUrl + "api/files?per_page=" + per_page + "&page=" + page,
          config
        )
        .then((res) => {
          commit("SET_DATA", res.data.data);
        });
    },

    regenereData({ commit }, { id }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + "api/ContentCsv", { historique_id: id }, config)
          .then((res) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
            commit("SET_IS_UPLOADING", false);
          });
      });
    },
    uploadFile({ commit }, { file }) {
      commit("SET_IS_UPLOADING", true);
    
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + "api/file_history", file, config)
          .then((res) => {
            
            commit("SET_IS_UPLOADING", false);
            resolve(true);
          })
          .catch((err) => {
            reject(err);
            commit("SET_IS_UPLOADING", false);
          });
      });
    },
    updateFile({ commit }, { file }) {
      commit("SET_IS_UPLOADING", true);
    
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .put(apiUrl + "api/file_history/"+file.id, file, config)
          .then((res) => {
            
            console.log('ici res',res)
            commit("update_File", res.data.data);
            resolve(true);
          })
          .catch((err) => {
            reject(err);
            commit("SET_IS_UPLOADING", false);
          });
      });
    },

    deleteHistoryFile({ commit }, { id }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .delete(apiUrl + `api/historique_file?historique_id=${id}`, config)
          .then((res) => {
            //this.dispatch("getData");
            commit("delete_File", id);
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data.data;
      state.total = data.last_page;
    },
    SET_IS_UPLOADING(state, data) {
      state.isUploading = data;
    },
    delete_File(state, value) {
      let index = state.data.findIndex(
        (file) => file.id === value
      );
      state.data.splice(index, 1);
    },
    update_File(state, data) {
      let index = state.data.findIndex(
        (file) => file.id === data.id
      );
      state.data.splice(index, 1, data);
    },

  },
};
