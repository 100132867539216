import axios from "../utils/axios";
import { apiUrl } from "../../global_config";
import Vue from 'vue';


export default {
  state: {
    liste_partenaire: [],
    total_pagination_partenaire: 0,
    isUploading: false,
  },
  getters: {
    liste_partenaire: (state) => state.liste_partenaire,
    total_pagination_partenaire: (state) => state.total_pagination_partenaire,
  },

  actions: {
    async getPartenaire({ commit }, { per_page, page }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios.get(apiUrl + "api/broker?per_page=" + per_page + "&page=" + page, config).then((res) => {

        commit("SET_DATA_PARTENAIRE", res.data.data);
      });
    },

    // delete partenaire
    deletePartenaire({ commit }, { id }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .delete(apiUrl + `api/broker?broker_id=${id}`, config)
          .then((response) => {
            //this.dispatch("getData");
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })
            commit("delete_Partenaire", id);
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // Create new Partenaire
    Create_Partenaire({ commit }, { data } = {}) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + "api/broker", data, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })
            commit("add_new_partenaire", response.data.data);
            // commit("SET_CURRENT_USER", response.data.user);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(true);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    //update item partenaire
    update_partenaire({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        axios
          .put(apiUrl + "api/broker/" + id, item, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })
            commit("update_Partenaire", response.data.data);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(true);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    //update_statu
    update_status_partenaire({ commit }, { id, status }) {
      return new Promise((resolve, reject) => {
        axios
          .put(apiUrl + "api/broker_status/" + id, { status: status }, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })

            // commit("SET_CURRENT_USER", response.data.user);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(true);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    destroy_permission({ commit }, { permission_key, broker_id }) {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        data: {
          permission: permission_key,
          broker_id: broker_id
        }
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(apiUrl + "api/permission", config)
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })
            // commit("SET_CURRENT_USER", response.data.user);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(true);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    create_permission({ commit }, { permission_key, broker_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + "api/permission", { permission: permission_key, broker_id: broker_id }, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })
            // commit("SET_CURRENT_USER", response.data.user);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(true);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
          });
      });
    },


    //update item obligation camps
    update_obligation_leads({ commit }, { campaign_id, item }) {
      return new Promise((resolve, reject) => {
        axios
          .put(apiUrl + "api/obligation_leads",
            {
              FirstName: item.FirstName,
              LastName: item.LastName,
              Title: item.Title,
              Street: item.Street,
              PostalCode: item.PostalCode,
              City: item.City,
              Phone: item.Phone,
              Email: item.Email,
              Birthdate: item.Birthdate,
              CollectionUrl: item.CollectionUrl,
              CollectionDate: item.CollectionDate,
              campaign_key: item.campaign_key,
              campaign_id: campaign_id,
              email_verification: item.email_verification,
              HeatingEnergy: item.HeatingEnergy,
              WaterHeaterEnergy: item.WaterHeaterEnergy,
              CookingEnergy: item.CookingEnergy,
              ElectricityProvider: item.ElectricityProvider,
              GasProvider: item.GasProvider,
              Linky: item.Linky,
              Gazpar: item.Gazpar,
              TariffOption: item.TariffOption,
              ElectricalPower: item.ElectricalPower,
              ElectricityConsumptionBase: item.ElectricityConsumptionBase,
              ElectricityConsumptionHP: item.ElectricityConsumptionHP,
              ElectricityConsumptionHC: item.ElectricityConsumptionHC,
              GasConsumption: item.GasConsumption,
              HousingType: item.HousingType,
              HouseSurfaceArea: item.HouseSurfaceArea,
              HouseholdSize: item.HouseholdSize,
              HousingOccupancyPeriod: item.HousingOccupancyPeriod,
              Insulation: item.Insulation,
              HousingStatus: item.HousingStatus,
              MovingProject: item.MovingProject,
              MovingDate: item.MovingDate,
              InternetProvider_opp: item.InternetProvider_opp,
              MobileProvider_opp: item.MobileProvider_opp,
              TelcoProviderPreference_opp: item.TelcoProviderPreference_opp,
              TelcoOptions_opp: item.TelcoOptions_opp,
              TelcoProviderLoyalty_opp: item.TelcoProviderLoyalty_opp,
              InternetProvider: item.InternetProvider,
              CommitmentPeriodInternet: item.CommitmentPeriodInternet,
              UnlimitedCallPhone: item.UnlimitedCallPhone,
              TvDecoder: item.TvDecoder,
              TvChannel: item.TvChannel,
              MobileProvider: item.MobileProvider,
              CommitmentPeriodMobile: item.CommitmentPeriodMobile,
              CallTimeMobile: item.CallTimeMobile,
              MobileOptions: item.MobileOptions,
              MobileWithSmartphone: item.MobileWithSmartphone,
              MobilePlansNumber: item.MobilePlansNumber,
              LeadSource: item.LeadSource,

              LeadSource_opp: item.LeadSource_opp,
              InternetBudget: item.InternetBudget,
              TelcoType: item.TelcoType,
              EnergyType: item.EnergyType,
              Gclid: item.Gclid,
              FBclid: item.FBclid,

              HasOpticalFibre_ext: item.HasOpticalFibre_ext,
              TelcoService_ext: item.TelcoService_ext,


              TelcoProposedOffer_opp:item.TelcoProposedOffer_opp,

              OptinEmail: item.OptinEmail,
              OptinSMS: item.OptinSMS,
              OptinPartners: item.OptinPartners,
              PDL: item.PDL,
              PCE: item.PCE,

              telephone_verification: item.telephone_verification,
              telephone_accept_insure: item.telephone_accept_insure,

              SubscriptionType_opp: item.SubscriptionType_opp,
              EnergyProvider_opp: item.EnergyProvider_opp,
              EnergyBudget_opp: item.EnergyBudget_opp,

              TelcoBudget_opp: item.TelcoBudget_opp,
              Search_opp: item.Search_opp,
              CriteriaEnergy: item.CriteriaEnergy,

            }
   
            , {
              headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": `Bearer ${localStorage.getItem("auth")}`,
              },
            })
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })
            // commit("SET_CURRENT_USER", response.data.user);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(true);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },

    update_generation_conf({ commit }, { id, status }) {
      return new Promise((resolve, reject) => {
        axios
          .put(apiUrl + "api/update_config_broker/" + id, { status: status }, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })

            // commit("SET_CURRENT_USER", response.data.user);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(response);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    update_generation_conf_dev({ commit }, { id, status }) {
      return new Promise((resolve, reject) => {
        axios
          .put(apiUrl + "api/update_config_broker_dev/" + id, { status: status }, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })

            // commit("SET_CURRENT_USER", response.data.user);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(response);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    update_generation_conf_prod({ commit }, { id, status }) {
      return new Promise((resolve, reject) => {
        axios
          .put(apiUrl + "api/update_config_broker_prod/" + id, { status: status }, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })

            // commit("SET_CURRENT_USER", response.data.user);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(response);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },

    reverification_email_leads({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(apiUrl + "api/reverification_leads_email", {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })

            // commit("SET_CURRENT_USER", response.data.user);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(response);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },



  },
  mutations: {
    SET_DATA_PARTENAIRE(state, data) {
      let response = data.data;
      response &&
        response.map((item) => (item.status = item.status == 1 ? true : false));
      state.liste_partenaire = data.data;
      state.total_pagination_partenaire = data.last_page;
    },
    SET_IS_UPLOADING(state, data) {
      state.isUploading = data;
    },
    delete_Partenaire(state, value) {
      let index = state.liste_partenaire.findIndex(
        (partenaire) => partenaire.id === value
      );
      state.liste_partenaire.splice(index, 1);
    },

    update_Partenaire(state, data) {
      let index = state.liste_partenaire.findIndex(
        (partenaire) => partenaire.id === data.id
      );
      state.liste_partenaire.splice(index, 1, data);
    },

    add_new_partenaire(state, data) {
      state.liste_partenaire.push(data)
    }
  },
};
