import axios from "../utils/axios";
import { apiUrl } from "../../global_config";
import Vue from 'vue';
export default {
  state: {
    operators: [],
  },
  getters: {
    operators: (state) => state.operators,
  },
  actions: {
    async getOperators({ commit }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios.get(apiUrl + "api/operateur", config).then((res) => {
        commit("SET_DATA_OPERATOR", res.data.data);
      });
    },
    async updateOperatorStatus({ commit }, { id, value }) {
      let status = value === true ? 1 : 2;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios
        .put(apiUrl + `api/operateur_status/${id}`, { status: status }, config)
        .then((response) => {
          Vue.notify({
            group: 'foo',
            title: '',
            text: response.data.message
          })
          this.dispatch("getOperators");
        });
    },
  },
  mutations: {
    SET_DATA_OPERATOR(state, data) {
      state.operators = data;
    },
  },
};
