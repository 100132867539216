import { apiUrl } from "../../global_config";
import axios from "axios";
import router from "../router/index";
import Vue from 'vue';
export default {
  state: {
    currentUser: null,
    userProfile: {
      id: null,
      name: null,
      password: null,
    },
    users: {
      data: [],
      total: 0,
    },
    isLogged: false
  },
  getters: {
    userProfile: (state) => state.userProfile,
    users: (state) => state.users,
    isLogged: state => state.isLogged,
  },
  mutations: {
    SET_CURRENT_USER(state, newValue) {
      state.userProfile = newValue;
    },
    SET_TOKEN_USER(state, newValue) {
      localStorage.setItem("auth", newValue);
    },
    SET_USERS(state, data) {
      state.users.data = data.data.data;
      state.users.total = data.data.last_page;
    },
    SET_LOGGED(state, newValue) {
      state.isLogged = newValue;
    },
    delete_Users(state, value) {
      let index = state.users.data.findIndex(
        (user) => user.id === value
      );
      state.users.data.splice(index, 1);
    },

  },
  actions: {
    // Logs in the current user.
    logIn({ commit }, { email, password } = {}) {
      let params = {
        email: email,
        password: password,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + "api/login", params, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
            },
          })
          .then((response) => {
            if (response.data.user.type == 'admin') {
              commit("SET_LOGGED", true);
              commit("SET_CURRENT_USER", response.data.user);
              commit("SET_TOKEN_USER", response.data.access_token);
            } else {
              let reset_user = {
                id: null,
                name: null,
                password: null
              }
              commit("SET_CURRENT_USER", reset_user);
              commit("SET_TOKEN_USER", null);
              commit("SET_LOGGED", false);
            }



            resolve(true);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },


    //get info current user connected via token stored in localstorage
    Whoami({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(apiUrl + "api/WhoAmi", {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          })
          .then((response) => {

            if (response.data.type == 'admin') {
              commit("SET_LOGGED", true);
              commit("SET_CURRENT_USER", response.data);

            } else {
              let reset_user = {
                id: null,
                name: null,
                password: null
              }
              commit("SET_CURRENT_USER", reset_user);
              commit("SET_TOKEN_USER", null);
              commit("SET_LOGGED", false);

            }



            resolve(true);
          })
          .catch((e) => {

            commit("SET_LOGGED", false);

            localStorage.clear();
            // router.push('/login');
            console.log(e);
            reject(e);
          });
      });
    },

    // Logs out the current user.
    logOut({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + "api/logout", [], {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: "Bearer " + localStorage.getItem("auth"),

            },
          })
          .then((response) => {
            let reset_user = {
              id: null,
              name: null,
              password: null
            }
            commit("SET_CURRENT_USER", reset_user);
            commit("SET_TOKEN_USER", null);
            commit("SET_LOGGED", false);

            resolve(true);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },





    async getUsers({ commit }, { per_page, page }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios
        .get(
          apiUrl + "api/users?per_page=" + per_page + "&page=" + page,
          config
        )
        .then((res) => {
          commit("SET_USERS", res.data);
        });
    },
    async addUser({ commit }, { data }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios.post(apiUrl + "api/users", data, config).then((response) => {
        Vue.notify({
          group: 'foo',
          title: '',
          text: response.data.message
        })

        //this.dispatch("getUsers");
      }).catch((err) => {
        if (err.response.data != undefined) {
          let msg_erreur = err.response.data.message
          for (const [key, value] of Object.entries(msg_erreur)) {
            Vue.notify({
              group: 'foo',
              title: key,
              text: value,
              type: 'error',
            })
          }
        }

        reject(err);
      });
    },

    deleteUser({ commit }, { id }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .delete(apiUrl + `api/users/${id}`, config)
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })
            commit("delete_Users", id);
            resolve(true);
          })
          .catch((err) => {
            if (err.response.data != undefined) {
              let msg_erreur = err.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }

            reject(err);
          });
      });
    },

    forgetPassword({ commit }, { email }) {

      return new Promise((resolve, reject) => {
        axios.put(apiUrl + "api/forgetPassword", { email: email }, []).then((response) => {
          Vue.notify({
            group: 'foo',
            title: '',
            text: response.data.message
          })
          resolve(true);
          //this.dispatch("getUsers");
        }).catch((err) => {
          reject(err);
        });
      });
    },

    resetPassword({ commit }, { password, code }) {

      return new Promise((resolve, reject) => {
        axios.put(apiUrl + "api/resetPassword", { password: password, code: code }, []).then((response) => {
          Vue.notify({
            group: 'foo',
            title: '',
            text: response.data.message
          })
          resolve(true);
          //this.dispatch("getUsers");
        }).catch((err) => {
          reject(err);
        });
      });
    },

    async UpdateUser({ commit }, { data,id }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios.put(apiUrl + "api/users/"+id, data, config).then((response) => {
        Vue.notify({
          group: 'foo',
          title: '',
          text: response.data.message
        })

        //this.dispatch("getUsers");
      }).catch((err) => {
        if (err.response.data != undefined) {
          let msg_erreur = err.response.data.message
          for (const [key, value] of Object.entries(msg_erreur)) {
            Vue.notify({
              group: 'foo',
              title: key,
              text: value,
              type: 'error',
            })
          }
        }

        reject(err);
      });
    },






  },
};
