<template>
  <header>
    <!-- <Loader v-if="loader" /> -->
    <div class="container">

      <div class="row col-12">
        <div class="col-3">
          <div class="logo">
            <img class="logo" src="../../assets/logo.svg" alt="">
          </div>
        </div>
        <div class="col-6">
          <div class="  container_actions">
            <!-- <router-link class="actions" to="/configuration">
              <span class="mr-1">
                <i class="icon-users"></i>
              </span>
              <span> Configuration</span>
            </router-link> -->

            <router-link class="actions" to="/eligibilite-fibre/configuration">
              <span class="mr-1">
                <i class="icon-users"></i>
              </span>
              <span> WS Eligiblité</span>
            </router-link>

            <router-link class="actions" to="/leads/configuration">
              <span class="mr-1">
                <i class="icon-users"></i>
              </span>
              <span> WS Leads</span>
            </router-link>

            <!-- <router-link class="actions" to="/liste-files">
              <span class="mr-1">
                <i class="icon-clock"></i>
              </span>
              <span> Historique</span>
            </router-link> -->

            <router-link class="actions" to="/users">
              <span class="mr-1">
                <i class="icon-user"></i>
              </span>
              <span> Utilisateurs</span>
            </router-link>

            <router-link class="actions" to="/broker">
              <span class="mr-1">
                <i class="icon-users"></i>
              </span>
              <span> Brokers</span>
            </router-link>

          

            

          </div>
        </div>
        <div class="col-3 menu-profil">
          <div class="menu">

            <ul>
              <li class="dropdown">
                <div class="user"> <span><i class="icon-icon-profile-user mr-2"></i> </span> <a href="#">{{
                  userProfile.name
                }}</a> </div>
                <div class="dropdown-content">

                  <div class="sign-out">
                    <span>
                      <i class="icon-icon-sign-out"></i>
                    </span>
                    <a href="#" @click="logout()"> Déconnexion</a>
                  </div>
                  

                 


                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div class="header-main">



      </div>

    </div>
  </header>
</template>  

<script>
import { mapActions, mapGetters } from 'vuex';
import Loader from "../Eligibility/Loader.vue";

export default {
  name: "TopNav",
  components: {
    Loader,
  },
  data() {
    return {
      loader: false
    };
  },
  computed: {
    ...mapGetters(['userProfile']),
  },
  methods: {
    ...mapActions(['logOut']),
    async logout() {
      this.loader = true;
      this.logOut()
        .then((response) => {
          if (response == true) {
            this.loader = false;

            this.$router.push(
              this.$route.query.redirectFrom || { name: "login" }
            );
          }
        })
        .catch((error) => {
          this.loader = false;
        });
    },
  },
};
</script>