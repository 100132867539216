import axios from "../utils/axios";
import { apiUrl } from "../../global_config";
import Vue from 'vue';
export default {
  state: {
    Liste_logs: [],
    total_pagination_logs: 0,
  },
  getters: {
    Liste_logs: (state) => state.Liste_logs,
    total_pagination_logs: (state) => state.total_pagination_logs,

  },
  actions: {
    async getLogs({ commit }, { search_key, per_page, page }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios.get(apiUrl + "api/logs?search_key=" + search_key + "&per_page=" + per_page + "&page=" + page, config).then((res) => {
        commit("SET_DATA_LOGS", res.data.data);
      });
    },

  },
  mutations: {
    SET_DATA_LOGS(state, data) {

      state.Liste_logs = data.data;
      state.total_pagination_logs = data.last_page;
    },
  },
};
