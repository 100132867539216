import Vue from 'vue'
import VueRouter from 'vue-router'

// import home from "../views/home.vue";
// import login from "../views/login.vue";
// import liste from "../views/liste-files.vue";

Vue.use(VueRouter)

const routes = [

  {
    path: "/wlc",
    name: "home",
    component: () => import(/* webpackChunkName: "login" */ '../views/Home.vue')
  },
  {
    path: "/",
    name: "login",
    beforeEnter: (to, from,next) => {
     
      if (localStorage.getItem("auth") != 'null' && localStorage.getItem("auth") != null) {
        next('/broker');
      } else {
      
        return next();
      }
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')

  },
  {
    path: "/liste-files",
    name: "liste",
    beforeEnter: (to, from,next) => {
     
      if (localStorage.getItem("auth") != 'null' && localStorage.getItem("auth") != null) {
        next();
      } else {
      
        return next('/');
      }
    },
    component: () => import(/* webpackChunkName: "liste_filter" */ '../views/liste-files.vue')
  },

  {
    path: "/users",
    name: "users",
    beforeEnter: (to, from,next) => {
     
      if (localStorage.getItem("auth") != 'null' && localStorage.getItem("auth") != null) {
        next();
      } else {
      
        return next('/');
      }
    },
    component: () => import(/* webpackChunkName: "liste_users" */ '../views/liste-users.vue')
  },
  {
    path: "/broker",
    name: "broker",
    beforeEnter: (to, from,next) => {
     
      if (localStorage.getItem("auth") != 'null' && localStorage.getItem("auth") != null) {
        next();
      } else {
      
        return next('/');
      }
    },
    
    component: () => import(/* webpackChunkName: "liste_broker" */ '../views/liste-partenaire.vue')
  },
  // {
  //   path: "/configuration",
  //   name: "configuration",
  //   beforeEnter: (to, from,next) => {
     
  //     if (localStorage.getItem("auth") != 'null' && localStorage.getItem("auth") != null) {
  //       next();
  //     } else {
      
  //       return next('/');
  //     }
  //   },
  //   component: () => import(/* webpackChunkName: "configuration" */ '../views/configuration.vue')
  // }

  {
    path: "/eligibilite-fibre/configuration",
    name: "eligibilite-fibre/configuration",
    beforeEnter: (to, from,next) => {
     
      if (localStorage.getItem("auth") != 'null' && localStorage.getItem("auth") != null) {
        next();
      } else {
      
        return next('/');
      }
    },
    component: () => import(/* webpackChunkName: "configuration" */ '../views/Eligibilite.vue')
  },


  


  {
    path: "/leads/configuration",
    name: "leads/configuration",
    beforeEnter: (to, from,next) => {
     
      if (localStorage.getItem("auth") != 'null' && localStorage.getItem("auth") != null) {
        next();
      } else {
      
        return next('/');
      }
    },
    component: () => import(/* webpackChunkName: "configuration" */ '../views/Leads.vue')
  },
  {
    path: "/forget_password",
    name: "forget_password",
    component: () => import(/* webpackChunkName: "login" */ '../views/Forget_password.vue')
  },
  {
    path: "/reset_password/:code",
    name: "reset_password",
    component: () => import(/* webpackChunkName: "login" */ '../views/Reset_password.vue')
  },




]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Before each route evaluates...
// router.beforeEach((routeTo, routeFrom, next) => {

//  if(routeTo.name=='login'){

//     if (localStorage.getItem("auth") != null) {
//       // Check if auth is required on this route
//       // (including nested routes).
//       // If auth isn't required for the route, just continue.
//       next('/liste-files');
//     } else {
      
//       return next();
//     }
//  }else if(routeTo.name!='home'){
//   if (localStorage.getItem("auth") != null) {
//     return next();
//   }else{
//     next('login');
//   }
//  }
//  else{
//   next();
//  }
  

// });

export default router
