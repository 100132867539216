<template>
  <div>
    <Header name="header_component" v-if="isLogged" />
    <router-view></router-view>
    <notifications group="foo" />

  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./components/navbar/Header.vue";
import Vue from 'vue'

export default {
  name: "app",
  metaInfo() {
    return {

      meta: [
        { name: 'robots', content: 'noindex,nofollow' }
      ],
      // etc...
    }
  },

  mounted() {
    if (localStorage.getItem('isStoredLogin') == undefined) {
      //localStorage.clear()
      //this.$router.push({ name: "login" });
    }
    this.Whoami()


  },



  computed: {
    ...mapGetters(['isLogged']),
  },
  methods: {
    ...mapActions(["Whoami", "logOut"]),


  },
  components: {
    Header,

  },

};
</script>
