import Vue from "vue";
import Vuex from "vuex";
import fichier from "./fichier";
import auth from "./auth";
import partenaire from "./partenaire";
import technologies from "./technologies";
import operateurs from "./operator";
import company from "./company";
import logs from "./logs";
import fileHistory from "./fileHistory";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    fichier,
    auth,
    partenaire,
    technologies,
    operateurs,
    company,
    logs,
    fileHistory
  },
});
