import axios from "../utils/axios";
import { apiUrl } from "../../global_config";
import Vue from 'vue';
export default {
  state: {
    companys: [],
    total_pagination_companys: 0,
  },
  getters: {
    companys: (state) => state.companys,
    total_pagination_companys: (state) => state.total_pagination_companys,

  },
  actions: {
    async getAllCompanys({ commit } ) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios.get(apiUrl + "api/campaigny", config).then((res) => {
        
        commit("SET_DATA_COMPANY", res.data.data);
      });
    },

    async getCompanys({ commit },{per_page,page}) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios.get(apiUrl + "api/campaigny?per_page=" + per_page + "&page=" + page, config).then((res) => {
        
        commit("SET_DATA_COMPANY", res.data.data);
      });
    },
    async updateCompanyStatus({ commit }, { id, status }) {
   
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios
        .put(
          apiUrl + `api/campaigny_status/${id}`,
          { status: status },
          config
        )
        .then((response) => {
          Vue.notify({
            group: 'foo',
            title: '',
            text: response.data.message
          })
          this.dispatch("getCompanys");
        });
    },

    async updateCompany({ commit }, { id, data }) {
   
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios
        .put(
          apiUrl + `api/campaigny/${id}`,
          data,
          config
        )
        .then((response) => {
          Vue.notify({
            group: 'foo',
            title: '',
            text: response.data.message
          })
          //this.dispatch("getCompanys");
        });
    },


     // delete partenaire
     deleteCompany({ commit }, { id }) {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        };
        return new Promise((resolve, reject) => {
          axios
            .delete(apiUrl + `api/campaigny/${id}`, config)
            .then((response) => {
              Vue.notify({
                group: 'foo',
                title: '',
                text: response.data.message
              })
              commit("delete_Company", id);
              resolve(true);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
  
      // Create new Partenaire
      Create_Company({ commit }, { data } = {}) {
        return new Promise((resolve, reject) => {
          axios
            .post(apiUrl + "api/campaigny", data, {
              headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": `Bearer ${localStorage.getItem("auth")}`,
              },
            })
            .then((response) => {
              Vue.notify({
                group: 'foo',
                title: '',
                text: response.data.message
              })
              resolve(true);
            })
            .catch((e) => {
              console.log(e);
              reject(e);
              //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
            });
        });
      },


       // delete broker_company
     deleteCompanyBroker({ commit }, { id_broker,id_company }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        data: {
          campany_id:id_company,
          broker_id:id_broker
        }

      };
      return new Promise((resolve, reject) => {
        axios
          .delete(apiUrl + `api/campaigny_broker`, config)
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createCompanyBroker({commit},{id_broker,id_company}){
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + "api/campaigny_broker", {campany_id:id_company,broker_id:id_broker}, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((response) => {
            Vue.notify({
              group: 'foo',
              title: '',
              text: response.data.message
            })
            // commit("SET_CURRENT_USER", response.data.user);
            // commit("SET_TOKEN_USER", response.data.access_token);
            resolve(true);
          })
          .catch((e) => {
            if (e.response.data != undefined) {
              let msg_erreur = e.response.data.message
              for (const [key, value] of Object.entries(msg_erreur)) {
                Vue.notify({
                  group: 'foo',
                  title: key,
                  text: value,
                  type: 'error',
                })
              }
            }
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },

  },
  mutations: {
    SET_DATA_COMPANY(state, data) {
      
      state.companys = data.data;
      state.total_pagination_companys = data.last_page;
    },

    delete_Company(state, value) {
        let index = state.companys.findIndex(
          (company) => company.id === value
        );
        state.companys.splice(index, 1);
      },

  },
};
