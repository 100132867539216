import axios from "../utils/axios";
import { apiUrl } from "../../global_config";


export default {
    state: {
      liste_ficheHistory: [],
      total_pagination_ficheHistory: 0,
      isUploading: false,
    },

    getters: {
      liste_ficheHistory: (state) => state.liste_ficheHistory,
      total_pagination_ficheHistory: (state) => state.total_pagination_ficheHistory,
    },

 actions: {
    async getFileHistory({ commit }, { per_page, page }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      };
      await axios.get(apiUrl + "api/file_history?per_page=" + per_page + "&page=" + page, config).then((res) => {

        commit("SET_DATA_FILEHISTORY", res.data.data);
      });
    },


    
  },

  mutations: {
    SET_DATA_FILEHISTORY(state, data) {
      let response = data.data;
      response &&
        response.map((item) => (item.status = item.status == 1 ? true : false));
      state.liste_fileHistory = data.data;
      state.total_pagination_fileHistory = data.last_page;
    },
  },
}


 
